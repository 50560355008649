<template>
    <div class="login">
     <PreLoader v-if="isLoading"></PreLoader>
      <div class="login__card row">
        <div class="login__card__first column">
          <div class="login__card__first__h1div">
            <h1 class="login__card__first__h1div__h1">Добро пожаловать</h1>
          </div>
          <div class="login__card__first__h2div">
            <h2 class="login__card__first__h2div__h2">в</h2>
          </div>
          <div class="login__card__first__h3div">
            <div class="login__card__first__h3div__h3"><span class="login__card__first__h3div__h3__tel">Tel</span><span class="login__card__first__h3div__h3__med">Med</span></div>
            
          </div>
          <div class="login__card__first__h4div">
            <h2 class="login__card__first__h4div__h4">Новая платформа медицины в Кыргызстане</h2>
          </div>
        </div>
        <div class="login__card__second column">
          <v-card class="login__card__second__card"  width="500px"  height="500" >
            <v-spacer></v-spacer>
              <v-card-title class="login__card__second__card__cardtitle">
                <h1 class="login__card__second__card__cardtitle__voyti">Войти в <span class="login__card__second__card__cardtitle__voyti__tel">Tel</span><span class="login__card__second__card__cardtitle__voyti__med">Med</span></h1>
                <h5 class="login__card__second__card__cardtitle__vedite">Введите логин и пароль чтоб войти</h5>
              </v-card-title>
              <v-card-text>
              
                <v-form ref="loginForm">
                
                  <v-text-field
                    v-model="username"
                    name="username" 
                    label="Логин"
                    prepend-icon="mdi-account-circle"
                    solo
                    :rules="[rules.required]"
                  /><!--:error="'username'"-->
                  <v-text-field
                    v-model="password" 
                    :type="showPass ? 'text' : 'password'"
                    name="password"
                    label="Пароль"
                    prepend-icon="mdi-lock"
                    :append-icon="showPass ? 'mdi-eye' : 'mdi-eye-off'"
                    @click:append="showPass = !showPass"
                    solo
                    :rules="[rules.required]"
                  /><!--:error="password ?'password' : '' "-->
                  <v-row>
                    <v-col md="6" sm="6" align="start">
                      <v-checkbox class="mt-0" label="Запомнить меня"></v-checkbox>
                    </v-col>
                    <v-col md="6" sm="6" align="end">
                      <router-link :to="{path: '/login'}"><h3>Забыли пароль?</h3></router-link>
                    </v-col>
                  </v-row>
                  <div class="login__card__second__card__login">
                    <v-btn class="login__card__second__card__login__btn" color="#2662F0" @click.prevent="validate">Войти</v-btn>
                  </div>
                </v-form>
              </v-card-text>
              <v-divider></v-divider>
              <v-card-actions>
                <v-row>
                    <v-col md="12" sm="12" align="end">
                      <v-btn class="login__card__second__card__esia" color="#2662F0">Войти с помощью ЕСИА</v-btn>
                    </v-col>
                </v-row>
              </v-card-actions>
            </v-card>
        </div>
      </div>
  </div>
</template>

<script>
import PreLoader from '@/components/preloader/PreLoader.vue'
import { mapActions } from 'vuex'
export default {
    name: 'Login',
    components:{
      PreLoader
    },
    data: () => (
    {
      username: '',
      password: '',
      showPass : false,
      modelValidations: {
        username: {
          required: true,
          min: 4
        },
        password: {
          required: true,
          min: 4
        },
      },
      rules:{required: value => !!value || "Обязательно"},
      isLoading: false,
	}),
	methods: {
		...mapActions('account', [ 'login' ]),
        validate () {
          this.isLoading = true;
          if (this.$refs.loginForm.validate()) {
            this.login({ username: this.username, password: this.password });
          }
          this.isLoading = false;
        },
	}
};
</script>
<style lang="scss" scoped>
.invalid {
		border: 1px solid red;
	}
.login{
  max-height: 100vh;
  width:100%;
  background-color:#ffffff;
  display: inline-block;
  &__card{
    width:100%;
    height: 100%;
    /*background: linear-gradient(to bottom, #50A9FC 0%, #2662F0 100%);*/
    &__first{
      width:50%;
      height: 100%;
      background: linear-gradient(to bottom, #5ABAFF 0%, #0B4CE6 100%);
      &__h1div{
        color:#fff;
        display:flex;
        justify-content: center;
        &__h1{
          margin: 20% 0 5% 0px;
          
        }
        
      }
      &__h2div{
        color:#fff;
        display:flex;
        justify-content: center;
        &__h2{
          margin: 0 0 5% 0px;
          
        }
      }
      &__h3div{
        color:#fff;
        display:flex;
        justify-content: center;
        &__h3{
          margin: 0 0 5% 0px;
          font-size:150px;
          font-weight: 700;
          &__med{
            color:aqua;
          }
        }
      }
      &__h4div{
        color:#fff;
        display:flex;
        justify-content: center;
        &__h4{
          margin: 0 0 24% 0px;
          
        }
      }
    }
    &__second{
      width:50%;
      display: flex;
      justify-content: center;
      background-color:#e9e6e6;
      &__card{
        margin-top:17%;
        background-color:#ffffff;
        &__cardtitle{
          margin:10px 0 10px 0;
          &__voyti{
            font-size:30px;
            width: 100%;
            display:flex;
            font-weight: 500;
            justify-content: center;
            margin:10px;
            &__tel{
              margin-left:10px;
              color:#2662F0;
              font-size: 35px;
              font-weight: 700;
            }
            &__med{
              font-size: 35px;
              font-weight: 700;
              color:aqua;
            }

          }
          &__vedite{
            display:flex;
            width: 100%;
            font-weight: 500;
            justify-content: center;
          }
        }
        &__login{
          
          display:flex;
          justify-content: flex-end;
          &__btn{
            color:#fff;
          }
        }
        &__esia{
          color:#fff;
        }
      }
      
    }
    
  }
}
</style>